@import url("https://fonts.googleapis.com/css2?family=PT+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap");

* {
  margin: 0;
  border: none;
  box-sizing: border-box;
  font-family: "PT Sans", sans-serif;
}

html {
  font-size: 62.5%;
}

h2 {
  font-size: 2.4rem;
  font-weight: 600;
  text-transform: capitalize;
}

i.fa.fa-map-marker {
  color: #1f2041;
}

i {
  padding-right: 0.5rem;
}
